<script setup lang="ts">
useHead({
	bodyAttrs: {
		class: 'dark:bg-gray-950',
	},
});

const route = useRoute();

const isLogin = computed(() => route.path === '/login');
</script>

<template>
	<div
		class="max-h-dvh h-dvh flex flex-col items-stretch sm:items-center justify-center overlay px-4"
	>
		<div class="gradient" />

		<div class="flex flex-col items-center space-y-6">
			<UColorModeButton class="absolute top-2 right-2" />
			<div class="w-full flex flex-row justify-center items-end">
				<UColorModeImage
					light="/mytpsa_logo_black.svg"
					dark="/mytpsa_logo_white.svg"
					alt="myTPSA Logo"
					class="shadow-logo w-24 z-10 opacity-50"
				/>
			</div>

			<UCard
				class="sm:max-w-sm w-full bg-white/75 dark:bg-white/5 backdrop-blur"
			>
				<slot />
			</UCard>

			<div
				v-if="isLogin"
				class="flex justify-center"
			>
				<NuxtLink
					to="/api/auth/google"
					external
				>
					<UButton
						variant="soft"
						icon="i-uim-google"
					>
						Staff Sign-in
					</UButton>
				</NuxtLink>
			</div>

			<p
				v-if="isLogin"
				class="text-gray-500 dark:text-gray-400 text-center text-sm"
			>
				Don't have a chapter?
				<a
					href="https://texaspublicsafetyassociation.org/membership/start"
					target="_blank"
					class="text-primary font-medium hover:underline"
				>Create One.</a>
			</p>
		</div>
		<!-- <p
			class="text-xs text-center text-gray-500 dark:text-gray-400 mt-10 sm:max-w-xs opacity-50 w-full"
		>
			By signing in and using myTPSA, you agree to our
			<NuxtLink
				to="/"
				class="underline"
			>
				Terms of Service
			</NuxtLink>
			and
			<NuxtLink
				to="/"
				class="underline"
			>
				Privacy Policy
			</NuxtLink>
			.
		</p> -->
	</div>
</template>

<style scoped>
.gradient {
	position: absolute;
	inset: 0;
	pointer-events: none;
	background: radial-gradient(
		50% 50% at 50% 50%,
		rgb(var(--color-primary-500) / 0.25) 0,
		#fff 100%
	);
	z-index: -1;
}

.dark {
	.gradient {
		background: radial-gradient(
			50% 50% at 50% 50%,
			rgb(var(--color-primary-400) / 0.1) 0,
			rgb(var(--color-gray-950)) 100%
		);
	}
}
</style>
